import { Routes, Route, Navigate } from 'react-router-dom'
import { CompanyRouter } from './Company'
import { ConsumerRouter } from './Consumer'
import { DeliveryRouter } from './Delivery'
import { DeviceRouter } from './Device'
import { BatchRouter } from './Batch'
import { ClientRouter } from './Client'

export const Router = () => {
  return (
    <Routes>
      <Route path="companies/*" element={<CompanyRouter/>}/>
      <Route path="deliveries/*" element={<DeliveryRouter/>}/>
      <Route path="consumers/*" element={<ConsumerRouter/>}/>
      <Route path="devices/*" element={<DeviceRouter/>}/>
      <Route path="batches/*" element={<BatchRouter/>}/>
      <Route path="clients/*" element={<ClientRouter/>}/>
      <Route index element={<Navigate to="/companies" replace={true}/>}/>
    </Routes>
  )
}