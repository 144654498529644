import { to } from '../helpers'
import styles from './MedianSpending.module.css'

const days = [1, 2, 3, 4, 5, 6, 0]
const daysOfWeek = { 1: 'Mon', 2: 'Tue', 3: 'Wed', 4: 'Thu', 5: 'Fri', 6: 'Sat', 0: 'Sun' }

export const MedianSpending = props => {
  const { median } = props

  return (
    <div className={styles.root}>
      <div className={styles.label}>Median spending</div>
      <div className={styles.table}>
        {days.map(i => (
          <div className={styles.cell} key={i}>
            <span>{daysOfWeek[i]}</span>
            {Math.round(to.L(median[i]) * 10) / 10}
          </div>
        ))}
      </div>

    </div>
  )
}