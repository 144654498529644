import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { http } from '../http'
import { Modal, Input, Button } from '../components'
import { useBatchContext } from './BatchContext'

export const BatchEditRoute = (props) => {
  const navigate = useNavigate()
  const { batch } = useParams()
  const [{ data }, setStore] = useBatchContext()
  const record = data.byId[batch]
  const [formValues, setFormValues] = useState(() => ({
    batch: record.id,
    K_lot: record.parameters['K_lot'],
    I_lot: record.parameters['I_lot'],
  }))

  return (
    <Modal heading="Lot details" onClose={() => navigate('/batches')}>
      <div className="mt-4">
        <div className="fs-6">ID</div>
        <div className="fs-5">{record.id}</div>
      </div>
      <div className="mt-4">
        <Input
          type="number"
          label="K"
          defaultValue={record.parameters['K_lot']}
          onChange={fieldValue => handleChange('K_lot', fieldValue)}
        />
      </div>
      <div className="mt-4 mb-8">
        <Input
          type="number"
          label="I"
          defaultValue={record.parameters['I_lot']}
          onChange={fieldValue => handleChange('I_lot', fieldValue)}
        />
      </div>
      <Button.Group>
        <Button.Outlined
          component={Link}
          to="/batches"
          text="Cancel"
        />
        <Button.Primary
          text="Save"
          onClick={handleCommit}
        />
      </Button.Group>
    </Modal>
  )

  function handleChange(name, value) {
    setFormValues(prev => ({...prev, [name]: value}))
  }

  function handleCommit() {
    http.fetch('/crm.batch.edit', formValues).then(res => {
      setStore(draft => {
        draft.status = 'pending'
      })
    })
    navigate('/batches')
  }
}