import { useEffect } from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { EmployeeContext } from './EmployeeContext'
import { EmployeeListRoute } from './EmployeeListRoute'
import { EmployeeCreateRoute } from './EmployeeCreateRoute'
import { normalizr } from '../helpers'

export const EmployeeRouter = () => {
  const { companyId } = useParams()
  const [store, setStore] = useImmer({
    data: {}, status: 'loading',
    query: { skip: 0, limit: 10, company: companyId }
  })
  const { status, query } = store

  useEffect(() => {
    if ('loading' === status || 'pending' === status) {
      http.fetch('/crm.employee.list', query).then(res => {
        setStore(prev => {
          if (!res.ok) {
            prev.status = 'rejected'
            return
          }

          prev.status = 'resolved'
          prev.data = normalizr(res.data)
        })
      })
    }
  }, [status, query, setStore]);

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <EmployeeContext.Provider value={[store, setStore]}>
      <EmployeeListRoute/>
      <Routes>
        <Route path="new" element={<EmployeeCreateRoute/>}/>
      </Routes>
    </EmployeeContext.Provider>
  )
}