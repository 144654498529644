import { Routes, Route } from 'react-router-dom'
import { EmployeeRouter } from '../Employee'
import { CompanyListRoute } from './CompanyListRoute'
import { CompanyClientsRoute } from './CompanyClientsRoute'
import { CompanyCreateRoute } from './CompanyCreateRoute'

export const CompanyRouter = () => {
  return (
    <Routes>
      <Route path=":companyId/employees/*" element={<EmployeeRouter/>} />
      <Route path=":company/clients" element={<CompanyClientsRoute/>} />
      <Route path="*" element={<CompanyListRoute/>}>
        <Route path="new" element={<CompanyCreateRoute/>}/>
      </Route>
    </Routes>
  )
}