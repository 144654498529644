import { clsx } from '../helpers'
import styles from './Switch.module.css'

export const Switch = props => {
  const { checked, loading, disabled, onChange } = props

  if (loading) {
    return '...'
  }

  return (
    <div
      className={
        clsx(styles.root,
          checked && styles.checked,
          disabled && styles.disabled,
        )
      }
      onClick={handleChange}
      children={<div className={styles.toggle}/>}
    />
  )

  function handleChange() {
    if (!onChange || true === disabled) {
      return
    }

    onChange(!checked)
  }
}