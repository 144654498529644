import { Link } from 'react-router-dom'
import { Table, Pagination, Heading, TabList, Datetime } from '../components'
import { to } from '../helpers'
import { useClientContext } from './ClientContext'

export const ClientListRoute = () => {
  const [{ data, query }, setStore] = useClientContext()

  return (
    <>
      <Heading text="Clients"/>
      <TabList
        selected={query.status - 1}
        choices={[
          ['Bound', () => handleQueryChange(draft => draft.status = 1)],
          ['Waiting', () => handleQueryChange(draft => draft.status = 2)],
        ]}
      />
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>ID</th>
            <th>Company</th>
            <th>App user</th>
            <th>Created</th>
            <th>Last delivery</th>
            <th>Pumps</th>
            <th>Delivered</th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => data.byId[id]).map(cur => (
            <tr key={cur.id}>
              <td>{cur.id}</td>
              <td>{cur.company}</td>
              <td>{cur.consumer || '—'}</td>
              <td>
                <Datetime value={cur.createdAt}/>
              </td>
              <td>
                <Datetime value={cur.restockedAt}/>
              </td>
              <td>
                <Link
                  to={`/devices/search?client=${cur.id}`}
                  children={cur.deviceCount}
                />
              </td>
              <td>
                <Link
                  to={`/deliveries/search?client=${cur.id}`}
                  children={`${to.L(cur.restockedVolumeSum)} L`}
                />
              </td>
            </tr>
          ))}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }

  function handleQueryChange(reducer) {
    setStore(draft => {
      reducer(draft.query)
      draft.status = 'pending'
      draft.query.skip = 0;
    })
  }
}