import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { TabList, Table, Heading, Pagination, Datetime } from '../components'
import { to, normalizr } from '../helpers'

export const ConsumerListRoute = props => {
  const { query: initialQuery } = props
  const [store, setStore] = useImmer({
    query: {
      ...initialQuery,
      type: 1,
      skip: 0, limit: 10,
      orderBy: { createdAt: 1 } },
  })

  const { status, data, query } = store

  useEffect(() => {
    if (undefined === status || 'pending' === status) {
      http.fetch('/crm.consumer.list', query).then(res => {
        setStore(draft => {
          if (!res.ok) {
            draft.status = 'rejected'
            return
          }

          draft.status = 'resolved'
          draft.data = normalizr(res.data)
        })
      })
    }
  }, [status, query, setStore])

  if (undefined === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <>
      <Heading text="App users"/>
      <TabList
        selected={query.type - 1}
        choices={[
          ['Commercial', () => handleQueryChange(draft => draft.type = 1)],
          ['Independent', () => handleQueryChange(draft => draft.type = 2)],
        ]}
      />
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>
              <Table.Sortable
                label="App user"
                direction={query.orderBy.id}
                onClick={handleOrderByChange('id')}
              />
            </th>
            <th hidden={2 === query.type}>Companies</th>
            <th>
              <Table.Sortable
                label="Created"
                direction={query.orderBy.createdAt}
                onClick={handleOrderByChange('createdAt')}
              />
            </th>
            <th hidden={2 === query.type}>
              <Table.Sortable
                label="Last delivery"
                direction={query.orderBy.restockedAt}
                onClick={handleOrderByChange('restockedAt')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Pumps"
                direction={query.orderBy.deviceCount}
                onClick={handleOrderByChange('deviceCount')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Mileage"
                direction={query.orderBy.volume}
                onClick={handleOrderByChange('volume')}
              />
            </th>
            <th hidden={2 === query.type}>
              <Table.Sortable
                label="Deliveried"
                direction={query.orderBy.restockedVolumeSum}
                onClick={handleOrderByChange('restockedVolumeSum')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Stock"
                direction={query.orderBy.balance}
                onClick={handleOrderByChange('balance')}
              />
            </th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => {
            const cur = data.byId[id]

            return (
              <tr key={cur.id}>
                <td>
                  <Link
                    to={`/deliveries/search?consumer=${cur.id}`}
                    children={cur.id}
                  />
                </td>
                <td hidden={2 === query.type}>
                  <Link
                    to={`${cur.id}/companies`}
                    children={cur.companyCount}
                  />
                </td>
                <td>
                  <Datetime value={cur.createdAt}/>
                </td>
                <td hidden={2 === query.type}>
                  <Datetime value={cur.restockedAt}/>
                </td>
                <td>
                  <Link
                    to={`/devices/search?consumer=${cur.id}`}
                    children={cur.deviceCount}
                  />
                </td>
                <td>{to.L(cur.volume)}</td>
                <td hidden={2 === query.type}>
                  {cur.restockedVolumeSum ? to.L(cur.restockedVolumeSum) : 0} L
                </td>
                <td>
                  <div>{to.L(cur.balance)}/{to.L(cur.restockedVolume)} L</div>
                  {cur.percentage}%
                </td>
              </tr>
            )
          })}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )

  function handleQueryChange(reducer) {
    setStore(draft => {
      reducer(draft.query)
      draft.status = 'pending'
      draft.query.skip = 0
    })
  }

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }

  function handleOrderByChange(property) {
    return direction => {
      setStore(draft => {
        draft.query.skip = 0
        draft.status = 'pending'
        draft.query.orderBy = { [property]: direction }
      })
    }
  }
}