import { Routes, Route } from 'react-router-dom'
import { wrapWithQueryParams } from '../components'
import { DeviceListRoute } from './DeviceListRoute'
import { DeviceEditRoute } from './DeviceEditRoute'

const DeviceSearchRoute = wrapWithQueryParams(DeviceListRoute)

export const DeviceRouter = props => {
  return (
    <Routes>
      <Route index element={<DeviceListRoute/>}/>
      <Route path=":device/edit" element={<DeviceEditRoute/>}/>
      <Route path="search" element={<DeviceSearchRoute/>}/>
    </Routes>
  )
}