import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { Link } from 'react-router-dom'
import { Table, Heading, TabList, Datetime, Pagination } from '../components'
import { to, normalizr } from '../helpers'

export const DeviceListRoute = props => {
  const { query: initialQuery } = props
  const [store, setStore] = useImmer({
    data: {},
    status: 'loading',
    query: {
      skip: 0, limit: 10,
      orderBy: { id: 1 },
      ...initialQuery
    }
  })

  const { status, query, data } = store

  useEffect(() => {
    if ('loading' === status || 'pending' === status) {
      http.fetch('/crm.device.list', query).then(res => {
        setStore(draft => {
          if (!res.ok) {
            draft.status = 'rejected'
            return
          }

          draft.status = 'resolved'
          draft.data = normalizr(res.data)
        })
      })
    }
  }, [status, query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <>
      <Heading
        text="Pumps"
      />
      <TabList
        selected={(() => {
          if (null == query.status) {
            return 0
          }

          return query.status
        })()}
        choices={[
          ['All', () => handleQueryChange(draft => delete draft.status)],
          ['Active', () => handleQueryChange(draft => draft.status = 1)],
          ['Not active', () => handleQueryChange(draft => draft.status = 2)],
          ['Retired', () => handleQueryChange(draft => draft.status = 3)],
        ]}
      />
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>
              <Table.Sortable
                label="ID"
                direction={query.orderBy.id}
                onClick={handleOrderByChange('id')}
              />
            </th>
            <th>Lot</th>
            <th>L. params</th>
            <th>P. params</th>
            <th style={{textAlign: 'right'}}>Companies</th>
            <th>App user</th>
            <th style={{textAlign: 'right'}}>
              <Table.Sortable
                label="Mileage"
                direction={query.orderBy.volume}
                onClick={handleOrderByChange('volume')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Updated"
                direction={query.orderBy.updatedAt}
                onClick={handleOrderByChange('updatedAt')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Activated"
                direction={query.orderBy.connectedAt}
                onClick={handleOrderByChange('connectedAt')}
              />
            </th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => {
            const cur = data.byId[id]

            return (
              <tr key={cur.id}>
                <td>
                  <Link to={`/devices/${cur.id}/edit`}>{cur.id}</Link>
                </td>
                <td>{cur.batch}</td>
                <td>
                  <div>K: {null == cur.parameters['K_lot'] ? '—' :  cur.parameters['K_lot']}</div>
                  <div>I: {null == cur.parameters['I_lot'] ? '—' :  cur.parameters['I_lot']}</div>
                </td>
                <td>
                  K: {null == cur.parameters['K_pump'] ? '—' :  cur.parameters['K_pump']}
                </td>
                <td style={{textAlign: 'right'}}>
                  {cur.companies}
                </td>
                <td>{cur.consumer || '—'}</td>
                <td style={{textAlign: 'right'}}>
                  <div>{to.L(cur.volume)} L</div>
                  <div>{to.duration(cur.duration)}</div>
                </td>
                <td>
                  <Datetime value={cur.updatedAt}/>
                </td>
                <td>
                  <Datetime value={cur.connectedAt}/>
                </td>
              </tr>
            )
          })}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }

  function handleOrderByChange(property) {
    return direction => {
      handleQueryChange(draft => {
        draft.orderBy = { [property]: direction }
      })
    }
  }

  function handleQueryChange(reducer) {
    setStore(draft => {
      reducer(draft.query)
      draft.status = 'pending'
      draft.query.skip = 0
    })
  }
}