import { Link, Outlet } from 'react-router-dom'
import { Table, Button, Pagination, Heading, Switch, TabList } from '../components'
import { useCompanyContext, CompanyContextProvider } from './CompanyContext'
import { to } from '../helpers'
import { http } from '../http'

export const CompanyListRoute = props => {
  return (
    <CompanyContextProvider {...props}>
      <CompanyList/>
    </CompanyContextProvider>
  )
}

const CompanyList = () => {
  const [{ data, query }, setStore] = useCompanyContext()

  return (
    <>
      <Heading
        text="Companies"
        button={
          <Button.Link
            label="Add"
            to="/companies/new"
          />
        }
      />
      <TabList
        selected={(() => {
          if (true === query.blocked) {
            return 2
          } else if (false === query.blocked) {
            return 1
          }

          return 0
        })()}
        choices={[
          ['All', () => handleQueryChange(draft => delete draft.blocked)],
          ['Working', () => handleQueryChange(draft => draft.blocked = false)],
          ['Suspended', () => handleQueryChange(draft => draft.blocked = true)],
        ]}
      />
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th style={{textAlign: 'right'}}>Employees</th>
            <th style={{textAlign: 'right'}}>Clients</th>
            <th style={{textAlign: 'right'}}>Pumps</th>
            <th style={{textAlign: 'right'}}>Deliveries</th>
            <th style={{textAlign: 'right'}}>Delivered</th>
            <th style={{textAlign: 'center'}}>Suspended</th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => {
            const cur = data.byId[id]

            return (
              <tr key={cur.id}>
                <td>{cur.id}</td>
                <td>{cur.name}</td>
                <td style={{textAlign: 'right'}}>
                  <Link
                    to={`/companies/${cur.id}/employees`}
                    children={cur.employeeCount}
                  />
                </td>
                <td style={{textAlign: 'right'}}>
                  <Link
                    to={`/companies/${cur.id}/clients`}
                    children={cur.clientCount}
                  />
                </td>
                <td style={{textAlign: 'right'}}>
                  <Link
                    to={`/devices/search?company=${cur.id}`}
                    children={cur.deviceCount}
                  />
                </td>
                <td style={{textAlign: 'right'}}>
                  <Link
                    to={`/deliveries/search?company=${cur.id}`}
                    children={cur.deliveryCount}
                  />
                </td>
                <td style={{textAlign: 'right'}}>{to.L(cur.restockedVolumeSum)} L</td>
                <td style={{textAlign: 'center'}}>
                  <Switch
                    checked={cur.blocked}
                    onChange={payload => handleStatusChange({company: cur.id, blocked: payload})}
                  />
                </td>
              </tr>
          )
          })}
          </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
      <Outlet/>
    </>
  )

  function handleStatusChange(payload) {
    http.fetch('/crm.company.status',payload).then(res => {
      setStore(draft => {
        draft.status = 'pending'
      })
    })
  }

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }

  function handleQueryChange(reducer) {
    setStore(draft => {
      reducer(draft.query)
      draft.status = 'pending'
      draft.query.skip = 0
    })
  }
}