import { Routes, Route } from 'react-router-dom'
import { ClientContextProvider } from './ClientContext'
import { ClientListRoute } from './ClientListRoute'

export const ClientRouter = props => {
  return (
    <ClientContextProvider {...props}>
      <Routes>
        <Route index element={<ClientListRoute/>}/>
      </Routes>
    </ClientContextProvider>
  )
}