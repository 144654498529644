export { flatten } from './flatten'

export const normalizr = ary => {
  return {
    ids: ary.map(cur => cur.id),
    byId: ary.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
  }
}

const pad = (str, maxLength = 2) => String(str).padStart(maxLength, '0')

export const to = {
  duration: ms => {
    const H = pad(Math.floor((ms / (1000 * 60 * 60)) % 24))
    const m = pad(Math.floor((ms / (1000 * 60)) % 60))
    const s = pad(Math.floor((ms / 1000) % 60))

    return `${H}:${m}:${s}`

  },
  sec: ms => ms / Math.pow(10, 3),
  L: ml => ml / Math.pow(10, 3),
  DateTime: (str, options = {}) => {
    const dt = new Date(str)
    const date = [
      pad(dt.getUTCDate(), 2),
      pad(dt.getUTCMonth() + 1, 2),
      pad(dt.getUTCFullYear(), 4)
    ].join('.')
    const time = [
      pad(dt.getUTCHours(), 2),
      pad(dt.getUTCMinutes(), 2),
      pad(dt.getUTCSeconds(), 2),
    ].join(':')

    options = { raw: false, ...options }

    if (true === options.raw) {
      return [date, time]
    }

    return `${date} ${time}`
  }
}

export const clsx = (...args) => args.filter(cur => Boolean(cur)).join(' ')