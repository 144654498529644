import { Link,  Outlet } from 'react-router-dom'
import { Heading, Table, Pagination } from '../components'
import { useBatchContext } from './BatchContext'

export const BatchListRoute = () => {
  const [{ data, query }, setStore] = useBatchContext()

  return (
    <>
      <Heading text="Lots"/>
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>
              <Table.Sortable
                label="ID"
                direction={query.orderBy.id}
                onClick={handleOrderByChange('id')}
              />
            </th>
            <th>
              <Table.Sortable
                label="Pumps"
                direction={query.orderBy.deviceCount}
                onClick={handleOrderByChange('deviceCount')}
              />
            </th>
            <th>K</th>
            <th>I</th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => data.byId[id]).map(cur => (
            <tr key={cur.id}>
              <td>
                <Link
                  to={`${cur.id}/edit`}
                  children={cur.id}
                />
              </td>
              <td>
                <Link
                  to={`/devices/search?batch=${cur.id}`}
                  children={cur.deviceCount}
                />
              </td>
              <td>{cur.parameters['K_lot']}</td>
              <td>{cur.parameters['I_lot']}</td>
            </tr>
          ))}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
      <Outlet/>
    </>
  )

  function handleOrderByChange(property) {
    return direction => {
      setStore(draft => {
        draft.status = 'pending'
        draft.query.skip = 0
        draft.query.orderBy = { [property]: direction }
      })
    }
  }

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }
}