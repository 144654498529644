import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { http, hasViolations } from '../http'
import { Modal, Input, Button } from '../components'
import { useCompanyContext } from './CompanyContext'
export const CompanyCreateRoute = () => {
  const navigate = useNavigate()
  const setCompanyStore = useCompanyContext()[1]
  const [formValues, setFormValues] = useState({ name: '' })
  const [violations, setViolations] = useState({})

  return (
    <Modal
      heading="Add company"
      onClose={() => navigate('/companies')}
    >
      <div className="mt-4 mb-8">
        <Input
          type="text"
          label="Name"
          onChange={fieldValue => handleChange('name', fieldValue)}
          error={violations['name']}
        />
      </div>
      <Button.Group>
        <Button.Outlined
          component={Link}
          to="/companies"
          text="Cancel"
        />
        <Button.Primary
          text="Save"
          onClick={handleCommit}
        />
      </Button.Group>
    </Modal>
  )

  function handleChange(name, value) {
    setFormValues(prev => ({...prev, [name]: value}))
  }

  function handleCommit() {
    http.fetch('/crm.company.create', formValues).then(res => {
      if (hasViolations(res)) {
        setViolations(res.errors.reduce((acc, cur) => ({ ...acc, [cur.property]: cur.message }), {}))
        return
      }
      setCompanyStore(prev => {
        prev.status = 'loading'
      })
      navigate('/companies')
    })
  }
}