import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { Link } from 'react-router-dom'
import { Table, Heading, Datetime, Pagination } from '../components'
import { http } from '../http'
import {normalizr, to} from '../helpers'

export const DeliveryListRoute = props => {
  const { query: initialQuery } = props
  const [store, setStore] = useImmer(() => {
    return {
      data: {},
      status: 'loading',
      query: {
        skip: 0, limit: 10,
        ...initialQuery,
      }
    }
  })
  const { data, status, query } = store

  useEffect(() => {
    if ('loading' === status || 'pending' === status) {
      http.fetch('/crm.delivery.list', query).then(res => {
        setStore(draft => {
          if (!res.ok) {
            draft.status = 'rejected'
            return
          }

          draft.status = 'resolved'
          draft.data = normalizr(res.data)
        })
      })
    }
  }, [status, query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <>
      <Heading text="Deliveries"/>
      <Table.Container empty={0 === data.ids.length}>
        <Table.Head>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Company</th>
            <th>App user</th>
            <th>Delivered</th>
            <th>Remained</th>
            <th>Total</th>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => {
            const cur = data.byId[id]

            return (
              <tr key={cur.id}>
                <td>{cur.id}</td>
                <td>
                  <Datetime value={cur.createdAt}/>
                </td>
                <td>{cur.company}</td>
                <td>
                  {cur.consumer ? (
                    <Link
                      to={`/consumers/search?id=${cur.consumer}`}
                      children={cur.consumer}
                    />
                  ): '—'}
                </td>
                <td>{to.L(cur.volume)} L</td>
                <td>{to.L(cur.balance)} L</td>
                <td>{to.L(cur.volume + cur.balance)} L</td>
              </tr>
            )
          })}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }
}