import { createContext, useContext, useEffect } from 'react'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { normalizr } from '../helpers'

const ClientContext = createContext(null)

export const useClientContext = () => useContext(ClientContext)

export const ClientContextProvider = props => {
  const { children, query: queryProp } = props
  const [store, setStore] = useImmer({
    data: {},
    status: 'loading',
    query: { skip: 0, limit: 10, status: 1, ...queryProp },
  })
  const { query, status } = store

  useEffect(() => {
    if ('loading' === status || 'pending' === status) {
      http.fetch('/crm.client.list', query).then(res => {
        setStore(draft => {
          if (!res.ok) {
            draft.status = 'rejected'
            return
          }

          draft.status = 'resolved'
          draft.data = normalizr(res.data)
        })
      })
    }

  }, [status, query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <ClientContext.Provider value={[store, setStore]}>
      {children}
    </ClientContext.Provider>
  )
}