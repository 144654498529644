import { Routes, Route } from 'react-router-dom'
import { wrapWithQueryParams } from '../components'
import { ConsumerListRoute } from './ConsumerListRoute'

const ConsumerSearchRoute = wrapWithQueryParams(ConsumerListRoute)

export const ConsumerRouter = () => {
  return (
    <Routes>
      <Route index element={<ConsumerListRoute/>}/>
      <Route path="search" element={<ConsumerSearchRoute/>}/>
    </Routes>
  )
}