import { NavLink, useNavigate } from 'react-router-dom'
import { SecurityContext } from '../http'
import { clsx } from '../helpers'
import styles from './Navigation.module.css'

const Links = [
  ['/companies', 'Companies', '#bank'],
  ['/deliveries', 'Deliveries', '#box'],
  ['/consumers', 'App users', '#apps'],
  ['/clients', 'Clients', '#person'],
  ['/devices', 'Pumps', '#database'],
  ['/batches', 'Lots', '#clipboard-text'],
]
export const Navigation = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.root}>
      <div className={styles.brand}>
        <svg>
          <use href="#brand"/>
        </svg>
      </div>
      {Links.map(([path, label, icon]) => (
        <NavLink
          key={path}
          to={path}
          className={({ isActive }) => clsx(styles.btn, isActive && styles.isActive)}
        >
          <svg>
            <use href={icon}/>
          </svg>
          <span>{label}</span>
        </NavLink>
      ))}

      <div className={clsx(styles.btn, styles.logout)} onClick={handleLogout}>
        <svg>
          <use href="#sign-out"/>
        </svg>
        <span>Logout</span>
      </div>
    </div>
  )


  function handleLogout() {
    SecurityContext.logOut()
    navigate('/')
  }
}