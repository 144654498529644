import { createContext, useContext, useEffect } from 'react'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { normalizr } from '../helpers'
export const CompanyContext = createContext(null)

export const useCompanyContext = () => useContext(CompanyContext)

export const CompanyContextProvider = props => {
  const { children, query: queryProp } = props
  const [store, setStore] = useImmer({
    data: {},
    status: 'loading',
    query: { skip: 0, limit: 10, ...queryProp },
  })
  const { status, query } = store

  useEffect(() => {
    if ('loading' === status || 'pending' === status) {
      http.fetch('/crm.company.list', query).then(res => {
        setStore(draft => {
          if (!res.ok) {
            draft.status = 'rejected'
            return
          }

          draft.status = 'resolved'
          draft.data = normalizr(res.data)
        })
      })
    }
  }, [status, query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <CompanyContext.Provider value={[store, setStore]}>
      {children}
    </CompanyContext.Provider>
  )
}