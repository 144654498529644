import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { normalizr } from '../helpers'
import { BatchContext } from './BatchContext'
import { BatchListRoute } from './BatchListRoute'
import { BatchEditRoute } from './BatchEditRoute'

const initialState = {
  data: {},
  status: 'loading',
  query: { skip: 0, limit: 10, orderBy: { id: -1 } },
}

export const BatchRouter = () => {
  const [store, setStore] = useImmer(initialState)
  const { query, status } = store

  useEffect(() => {
    if ('loading' === status || 'pending' === status) {
      http.fetch('/crm.batch.list', query).then(res => {
        setStore(draft => {
          if (!res.ok) {
            draft.status = 'rejected'
            return
          }

          draft.status = 'resolved'
          draft.data = normalizr(res.data)
        })
      })
    }

  }, [status, query, setStore])

  if ('loading' === status) {
    return 'Loading...'
  } else if ('rejected' === status) {
    return 'Failed to load resource.'
  }

  return (
    <BatchContext.Provider value={[store, setStore]}>
      <Routes>
        <Route index element={<BatchListRoute/>}/>
        <Route element={<BatchListRoute/>}>
          <Route path=":batch/edit" element={<BatchEditRoute/>}/>
        </Route>
      </Routes>
    </BatchContext.Provider>
  )
}