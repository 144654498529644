import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Modal, Input, Button } from '../components'
import { http, hasViolations } from '../http'
import { useEmployeeContext } from './EmployeeContext'

export const EmployeeCreateRoute = () => {
  const { companyId } = useParams()
  const navigate = useNavigate()
  const setStore = useEmployeeContext()[1]
  const [formValues, setFormValues] = useState({ username: '' })
  const [violations, setViolations] = useState({})

  return (
    <Modal
      heading="Add employee"
      onClose={() => { navigate(`/companies/${companyId}/employees`) }}
    >
      <div className="mt-4 mb-8">
        <Input
          label="Username"
          type="text"
          error={violations['username']}
          onChange={fieldValue => handleChange('username', fieldValue)}
        />
      </div>
      <Button.Group>
        <Button.Outlined
          component={Link}
          to={`/companies/${companyId}/employees`}
          text="Cancel"
        />
        <Button.Primary
          text="Save"
          onClick={handleCommit}
        />
      </Button.Group>
    </Modal>
  )

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }

  function handleCommit() {
    http.fetch('/crm.employee.create', {
      ...formValues,
      resetUrl: `${process.env.REACT_APP_RESET_URL}`,
      company: companyId,
    }).then(res => {
      if (hasViolations(res)) {
        setViolations(res.errors.reduce((acc, cur) => ({ ...acc, [cur.property]: cur.message }), {}))
        return
      }
      setStore(prev => {
        prev.status = 'loading'
      })
      navigate(`/companies/${companyId}/employees`)
    })
  }
}