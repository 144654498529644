import { to } from '../helpers'

export const Datetime = props => {
  const { value } = props

  if (null == value || '' === value) {
    return '—'
  }

  const [date, time] = to.DateTime(value, { raw: true })

  return (
    <span>
      <span>{date}</span>
      <span className="d-block text-muted">{time}</span>
    </span>
  )
}