import { useLocation, useParams } from 'react-router-dom'
import { http } from '../http'
import {Table, Button, Heading, Switch, TabList, Pagination} from '../components'
import { useEmployeeContext } from './EmployeeContext'

export const EmployeeListRoute = () => {
  const location = useLocation()
  const { companyId } = useParams()
  const [{ data, query }, setStore] = useEmployeeContext()

  return (
    <>
      <Heading
        text={`${companyId} employees`}
        button={
          <Button.Link
            label="Add"
            to={`${location.pathname}/new`}
          />
        }
      />
      <TabList
        selected={(() => {
          if (true === query.blocked) {
            return 2
          } else if (false === query.blocked) {
            return 1
          }

          return 0
        })()}
        choices={[
          ['All', () => handleQueryChange(draft => delete draft.blocked)],
          ['Working', () => handleQueryChange(draft => draft.blocked = false)],
          ['Blocked', () => handleQueryChange(draft => draft.blocked = true)],
        ]}
      />
      <Table.Container empty={0 === data.ids.length}>
        <colgroup>
          <col style={{width: 128}}/>
          <col style={{width: 320}}/>
          <col style={{width: 114}}/>
          <col/>
        </colgroup>
        <Table.Head>
          <tr>
          <th>ID</th>
            <th>Username</th>
            <th style={{textAlign: 'center'}}>Blocked</th>
            <th/>
          </tr>
        </Table.Head>
        <Table.Body>
          {data.ids.map(id => {
            const cur = data.byId[id]

            return (
              <tr key={cur.id}>
                <td>{cur.id}</td>
                <td>{cur.username}</td>
                <td style={{textAlign: 'center'}}>
                  <Switch
                    checked={cur.blocked}
                    onChange={payload => handleStatusChange({ employee: cur.id, blocked: payload })}
                  />
                </td>
                <td/>
              </tr>
            )
          })}
        </Table.Body>
      </Table.Container>
      <Pagination
        skip={query.skip}
        limit={query.limit}
        onChange={handlePaginationChange}
      />
    </>
  )
  
  function handleStatusChange(payload) {
    http.fetch('/crm.employee.status', payload).then(res => {
      setStore(draft => {
        draft.status = 'pending'
      })
    })
  }

  function handlePaginationChange(payload) {
    setStore(draft => {
      draft.status = 'pending'
      draft.query.skip = payload.skip
      draft.query.limit = payload.limit
    })
  }

  function handleQueryChange(reducer) {
    setStore(draft => {
      reducer(draft.query)
      draft.status = 'pending'
      draft.query.skip = 0
    })
  }
}