import { useEffect } from 'react'
import { useImmer } from 'use-immer'
import { useNavigate, useParams } from 'react-router-dom'
import { http } from '../http'
import { to } from '../helpers'
import { Modal as ModalRoot, Button, Radio, Input } from '../components'
import { MedianSpending } from './MedianSpending'

export const DeviceEditRoute = () => {
  const navigate = useNavigate()
  const { device } = useParams()
  const [store, setStore] = useImmer({
    status: 'loading',
    record: {},
    formValues: {}
  })

  useEffect(() => {
    http.fetch('/crm.device.get', { id: device }).then(res => {
      setStore(draft => {
        if (!res.ok) {
          draft.status = 'rejected'
          return
        }

        const record = res.data

        draft.formValues = {
          device: record.id,
          voided: record.voided,
          K_pump: record.parameters['K_pump']
        }
        draft.record = record
        draft.status = 'resolved'
      })
    })
  }, [device, setStore])

  const { status, record } = store

  return (
    <Modal
      status={status}
      onClose={() => navigate(-1)}
      children={() => (
        <>
          <div className="mt-4">
            <div className="fs-6">ID</div>
            <div className="fs-5">{record.id}</div>
          </div>
          <div className="mt-4">
            <div className="fs-6">Mileage</div>
            <div className="fs-5">
              {to.L(record.volume)} L / {to.duration(record.duration)}
            </div>
          </div>
          <div className="mt-4">
            <div className="fs-6">Updated</div>
            <div className="fs-5">
              {null == record.updatedAt ? '—' : to.DateTime(record.updatedAt)}
            </div>
          </div>
          <div className="mt-4">
            <div className="fs-6">Status</div>
            <div className="mt-1">
              <Radio
                label={record.connectedAt ? 'Active' : 'Not active'}
                name="status"
                defaultChecked={!record.voided}
                onChange={() => handleChange('voided', false)}
              />
            </div>
            <div className="mt-1">
              <Radio
                label="Retired"
                name="status"
                defaultChecked={record.voided}
                onChange={() => handleChange('voided', true)}
              />
            </div>
          </div>
          <div className="mt-4">
            <Input
              type="number"
              label="K"
              defaultValue={record.parameters['K_pump']}
              onChange={fieldValue => handleChange('K_pump', fieldValue)}
            />
          </div>
          <div className="mt-4 mb-8">
            <MedianSpending median={record.median}/>
          </div>
          <Button.Group>
            <Button.Outlined
              text="Cancel"
              onClick={() => navigate(-1)}
            />
            <Button.Primary
              text="Save"
              onClick={handleCommit}
            />
          </Button.Group>
        </>
      )}
    />
  )

  function handleCommit() {
    http.fetch('/crm.device.edit', store.formValues).then(() => {
      navigate(-1)
    })
  }

  function handleChange(name, value) {
    setStore(draft => {
      draft.formValues[name] = value
    })
  }
}

function Modal(props) {
  const { status, children, ...ModalProps} = props

  return (
    <ModalRoot
      heading={'resolved' === status && 'Pump details'}
      {...ModalProps}
    >
      {'loading' === status && 'Loading...'}
      {'rejected' === status && 'Loading...'}
      {'resolved' === status && children()}
    </ModalRoot>
  )
}

